import React, { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import { startScroll, stopScroll } from "../../utils/scroll-config";
import CustomButton from "../core/ButtonDefault";
import Buy from "./wallet-parts/Buy";
import useAxiosREST from "../../utils/hooks/useAxiosAPI";
import Deposit from "./wallet-parts/Deposit";
import Withdraw from "./wallet-parts/Withdraw";
import Info from "./wallet-parts/Info";

import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
SwiperCore.use([FreeMode]);

const tabs = [
  { key: "buy", title: "Buy $TICO" },
  { key: "deposit", title: "Deposit $TICO" },
  { key: "withdraw", title: "Withdraw $TICO" },
  { key: "info", icon: "info", title: "Info" },
];

const parts = {
  buy: Buy,
  deposit: Deposit,
  withdraw: Withdraw,
  info: Info,
};

const WalletPopUp = (props) => {
  const { state, auth } = props;
  const opened = state.getState().showWallet;
  const [isShow, setIsShow] = useState(false);
  const [activeTab, setActiveTab] = useState("deposit");
  const [isUserKYC, setUserKYC] = useState(false);
  const { fetchAPI } = useAxiosREST();

  const Component = parts[activeTab];

  const fetchPaymentUuid = async () => {
    // const res = await fetchAPI.post(`generate-payment-uuid`);  //TODO
    const ress = {
      uuid: "9cb1592a-7832-4e10-9d9a-743165841fd4",
    };

    if (ress.uuid) {
      window.uuid = ress.uuid;
    }
  };

  const onOpenPopUp = () => {
    setIsShow(true);
    stopScroll();
  };

  const onComplete = () => {
    state.setShowWallet(false);
    startScroll();
  };

  const beforeClose = () => {
    return true;
  };

  const onClosePopUp = () => {
    const checkIsCanClose = beforeClose();

    if (!checkIsCanClose) return;

    setIsShow(false);
    gsap.to([], { duration: 0.3, onComplete });
  };

  const handleChangeTab = (g) => {
    setActiveTab(g);
  };

  useEffect(() => {
    fetchPaymentUuid();
    opened && gsap.to([], { duration: 1, onStart: onOpenPopUp });
    return () => {};
  }, [opened]);

  // useEffect(() => {
  //   setUserKYC(auth?.user_kyc || false);
  //   return () => {};
  // }, [auth]);

  if (!opened) return <></>;
  return (
    <>
      <div className={`pop_up wallet ${isShow ? "active" : ""}`}>
        <div className="overflow df jc-c ai-st">
          <div className={`content df fd-c`}>
            <h2 className="fixel_36 text-center top-title">Wallet</h2>
            <Swiper
              slidesPerView={"auto"}
              className="tabs top-tabs df br-20"
              freeMode={true}
            >
              <SwiperSlide className="slide df">
                {tabs.map((tab) => (
                  <div
                    onClick={() => handleChangeTab(tab.key)}
                    key={tab.key}
                    className={`def grey type-32 br-14 tab pointer ${tab.key} ${
                      activeTab == tab.key ? "active" : ""
                    }`}
                  >
                    <div
                      className={`icon-24 ar-1 icon_${tab.icon || "activity"}`}
                    ></div>
                    <span className="txt">{tab.title}</span>
                  </div>
                ))}
              </SwiperSlide>
            </Swiper>

            {Component && (
              <Component
                {...{
                  ...props,
                  auth,
                  isUserKYC,
                  setUserKYC,
                  onClose: onClosePopUp,
                  changeTab: handleChangeTab,
                }}
              />
            )}

            <button
              className="df close close_icon"
              onClick={onClosePopUp}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletPopUp;
