import React, { useContext, useEffect, useState } from "react";
import useAxiosREST from "../../utils/hooks/useAxiosAPI";
import AuthContext from "../../context/AuthProvider";
import * as Sentry from "@sentry/browser";


export default function setUserData() {
  const [user, setUser] = useState({});
  const { fetchAPI } = useAxiosREST();
  const { setAuth } = useContext(AuthContext);

  const getUser = async () => {
    const data = await fetchAPI.get("user/profile");

    if (data?.username) {
      const my_rank = await getRank();
      window["user_email"] = data?.email;

      const { appearance: app, ...rest } = data;
      const userData = {
        avatar: app?.profileAvatar,
        border: app?.profileAvatarBorder,
        cursor: app?.customCursor,
        ...rest,
        my_rank,
      };

      setAuth((e) => ({ ...e, ...userData }));
      setUser(userData);
    }

    if (process.env.NODE_ENV === "production") {
      Sentry.setUser({ email: data?.email, username: data?.username });
    }
  };
  const getRank = async () => {
    const data = await fetchAPI.get("user/rank");

    return data;
  };

  return { user, getUser, getRank };
}
