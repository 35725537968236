import React from "react";
// import Discord from "../../assets/img/socials/discord.svg";
// import Facebook from "../../assets/img/socials/facebook.svg";
// import Medium from "../../assets/img/socials/medium.svg";
// import Telegram from "../../assets/img/socials/telegram.svg";
// import Twitter from "../../assets/img/socials/twitter.svg";
// import Youtube from "../../assets/img/socials/youtube.svg";
// import Tiktok from "../../assets/img/socials/tiktok.svg";
// import Instagram from "../../assets/img/socials/instagram.svg";
// import Threads from "../../assets/img/socials/threads.svg";
// import Twitch from "../../assets/img/socials/Twitch.svg";
// import Kicks from "../../assets/img/socials/Kicks.svg";
// import Linkedin from "../../assets/img/socials/Linkedin.svg";

const soc = [
  {icon: "twitter", link: "https://twitter.com/Funtico_com",},
  {icon: "telegram", link: "https://t.me/FunticoPortal",},
  {icon: "discord", link: "https://discord.gg/kN4kAAHvQd",},
  {icon: "facebook", link: "https://www.facebook.com/profile.php?id=61551632833409",},
  {icon: "tiktok", link: "https://www.tiktok.com/@funtico.com?_t=8jdJxCTyQrp&_r=1",},
  {icon: "instagram", link: "https://www.instagram.com/funtico_com/",},
  {icon: "linkedin", link: "https://www.linkedin.com/company/funtico",},
  {icon: "medium", link: "https://medium.com/@funtico",},
  {icon: "youtube", link: "https://www.youtube.com/channel/UCKNSppHiWRtKlqxyvAntNwQ",},
  // {icon: "tiktok", link: "https://www.tiktok.com/@funtico.games",},
  // {icon: "twitch", link: "https://www.twitch.tv/funtico_official",},
  // {icon: "kicks", link: "https://kick.com/funtico",},

];
export default function Socials({ clas }) {
  return (
    <div className={`socials ${clas ?? ""}`}>
      {soc.map((social, i) => (
        <a key={i} href={social.link} target="_blank" className={"network df mask_" + social.icon}>
        </a>
      ))}
    </div>
  );
}
