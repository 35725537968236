import React, { useEffect, useRef } from "react";
import showMessage from "../../utils/showMessage";
export default function InfoMessage({ target, children }) {
  const boxRef = useRef(null);

  useEffect(() => {
    const buttons =
      typeof target === "string"
        ? document.querySelectorAll(target)
        : [target.current];

    const showInfo = (e) => {
      showMessage(e, boxRef.current);
    };

    if (!buttons.length) return;

    buttons.forEach((t) => {
    t?.addEventListener("mouseenter", showInfo);
    t?.addEventListener("mouseleave", showInfo);
    });

    return () => {
      buttons.forEach((t) => {
        t?.removeEventListener("mouseenter", showInfo);
        t?.removeEventListener("mouseleave", showInfo);
      });
    };
  }, [target.current]);

  return (
    <>
      <div className="info_message" ref={boxRef}>
        <div className="wrapper br-8">{children}</div>
      </div>
    </>
  );
}
