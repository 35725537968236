import React, { useEffect, useState } from "react";
import Flow from "./components/Withdraw-Flow";
import Confirm from "./components/Withdraw-Confirm";
import { payToLocaleString } from "../../../utils/utils";
import CustomButton from "../../core/ButtonDefault";

const parts = {
  confirm: Confirm,
  flow: Flow,
};

export default function Withdraw(props) {
  const { onClose, state, auth } = props;
  const [activePart, setActivePart] = useState("flow");
  const amountState = useState("");
  const addressState = useState("");
  const Component = parts[activePart];

  const changePart = (p = "") => {
    setActivePart(p);
  };

  const onConfirm = () => {
    onClose();

    state.setPopUp("data", {
      custom: (e) => (
        <div className="withdraw-confirm df fd-c ai-c text-center gap-32">
          <p className="fixel_26">Withdrawal Successful!</p>
          <div className="df fd-c ai-c gap-16">
            <p className="fixel_16_sbl">
              To confirm your withdrawal request of
            </p>
            <div className="df ai-c gap-6">
              <div className="tico_icon"></div>
              <p className="fixel_26">{payToLocaleString(amountState[0])}</p>
            </div>
            <p className="fixel_16_sbl">
              please check the email sent on{" "}
              {auth?.email || "youremail@funtico.com"}
            </p>
          </div>
          <CustomButton
            onClick={() => state.setPopUp("")}
            classname={`grey`}
            def={40}
            title={"Close"}
          />
        </div>
      ),
    });
  };

  return (
    <>
      <Component
        {...{ ...props, changePart, amountState, addressState, onConfirm }}
      />
    </>
  );
}
