import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

export default function Textarea({
  callback,
  label,
  handleChange,
  def,
  error,
  max,
  ...props
}) {
  const onChange = (e) => {
    let v = e.target.value;
    if (max && max < v.length) return;
    handleChange(e);
  };
  useEffect(() => {
    callback && callback(props.value);
    return () => {};
  }, [props.value]);

  return (
    <label
      className={`label textarea ${props.value ? "active" : ""} ${
        error ? "error" : ""
      }`}
    >
      {label || error ? (
        <div
          className="name fixel_14"
          dangerouslySetInnerHTML={{ __html: error || label }}
        ></div>
      ) : null}
      <div className="row">
        <TextareaAutosize
          minRows={5}
          {...props}
          onChange={onChange}
          autoComplete="off"
        />

        <div className="resize"></div>
        <div className="border"></div>
      </div>
      {max ? (
        <div
          className={`max fixel_14 as-fe${
            props.value.length == max ? " done" : ""
          }`}
        >
          {props.value.length ?? 0}/{max}
        </div>
      ) : null}
    </label>
  );
}
