import React, { useEffect, useState } from "react";
import Input from "../../../core/labels/Input";

import CustomButton from "../../../core/ButtonDefault";
import SelectCurrency from "./SelectCurrency";
import Providers from "./Providers";
import Transaction from "./Crypto-Transaction";

import currencies from "./data/crypto-currencies.json";
import providersJson from "./data/crypto-providers.json";

import { payToLocaleString } from "../../../../utils/utils";
import useAxiosREST from "../../../../utils/hooks/useAxiosAPI";
window["crypto-currencies"] = currencies; //TODO

const labelPlay = {
  placeholder: "Enter Amount",
  name: "amount",
  type: "number",
  autoComplete: "false",
  required: true,
  tip: { title: "I want to pay" },
};

const labelBonus = {
  placeholder: "Enter Bonus Code",
  name: "amount",
  autoComplete: "false",
  required: true,
  tip: { title: "Bonus Code (Optional)" },
};

export default function Crypto(props) {
  const { back, cryptoExs } = props;
  const [amount, setAmount] = useState("");
  const [bonusCode, setBonusCode] = useState("");
  const [currency, setCurrency] = useState("");
  const [isProviders, setIsProviders] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);

  const [currenciesArray, setCurrencies] = useState([]);
  const [selectedArray, setSelectedArray] = useState({});
  const [exchangeResult, setExchangeResult] = useState({});
  const [amountError, setAmountError] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const { fetchAPI } = useAxiosREST();


  const goToProviders = () => {
    setIsProviders(true);
  };
  const goToTransaction = (provider) => {
    const url = `cashier/fiat/providers/simplex/create-payment-session`;

    const params = {
      fromCurrencyCode: currency,
      fromCurrencyAmount: amount,
      paymentUuid: window.uuid,
      provider: provider.provider,
    };

    // const ress = await fetchAPI.post(url, params);  //TODO

    const ress = {
      message: "Payment initialized",
      data: {
        uuid: "9cb994bc-a0f7-40f8-9f81-17acc400b4d7",
        address: "3HJVf9ny5nqMD3ZKXRzy51uVH5sp8x6XoU",
        memo: null,
        network: "btc",
        amount: 0.10164373,
        currency: "btc",
      },
    };

    if (ress.message) {
      setShowTransaction(true);
      setTransactionData(ress);
    }
  };

  const getExchangeProviders = async () => {
    console.log("getExchangeProviders");
    const url = `cashier/crypto/estimated-exchange-result-for-all-providers`;
    const params = {
      fromCurrencyCode: currency,
      fromCurrencyAmount: amount,
    };
    // const ress = await fetchAPI.get(url, params); //TODO
    const ress = providersJson;

    return ress;
  };

  // Get all available currencies
  useEffect(() => {
    const getAvailableCurrencies = async () => {
      let available = window["crypto-currencies"];
      if (!available) {
        available = await fetchAPI.get(`cashier/crypto/available-currencies`); //TODO
      }
      setCurrencies(available);
      setCurrency("btc");
    };
    setCurrencies();
    getAvailableCurrencies();

    return () => {};
  }, []);

  // Get selected currency data (need min and max)
  useEffect(() => {
    if (currency && currenciesArray.length) {
      const selectedCurr = currenciesArray.find((c) => c.code == currency);
      setSelectedArray(selectedCurr);
    }

    return () => {};
  }, [currency, currenciesArray]);

  // Show error if have min/max
  useEffect(() => {
    let err = null;

    if (amount) {
      const { min, max } = selectedArray;
      if (
        Boolean(min || max) &&
        (amount < Number(min) || amount > Number(max))
      ) {
        err = `Minimum: ${min} ${currency}, maximum: ${max} ${currency}`;
      }
    }
    setAmountError(err);
    return () => {};
  }, [amount, selectedArray]);

  // Get estimated exchange result
  useEffect(() => {
    let sto = null;
    let stoFirst = null;

    const getExchangeResult = async () => {
      console.log("getExchangeResult");
      sto && clearTimeout(sto);

      const url = `cashier/crypto/estimated-exchange-result`;

      const params = {
        fromCurrencyCode: currency,
        fromCurrencyAmount: amount,
      };

      // const ress = await fetchAPI.get(url, params);  //TODO
      const ress = {
        rawExchangeRate: "1.00000000",
        exchangeRate: "1.000",
        tico: "10.00",
        fromCurrencyAmount: "10",
        fromCurrencyCode: "btc",
      };

      setExchangeResult(ress);
      sto = setTimeout(getExchangeResult, 1000);
    };

    if (amount && !isProviders) {
      stoFirst = setTimeout(getExchangeResult, 500);
    }

    return () => {
      sto && clearTimeout(sto);
      stoFirst && clearTimeout(stoFirst);
    };
  }, [amount, currency, isProviders]);

  return showTransaction && transactionData ? (
    <Transaction {...props} {...transactionData} fetchAPI={fetchAPI} />
  ) : isProviders ? (
    <Providers
      back={() => setIsProviders(false)}
      {...props}
      toConfirm={goToTransaction}
      getProviders={getExchangeProviders}
    >
      <div className="crypto-exs df fd-c ai-c gap-16" data-hide-tabs>
        <p className="fixel_22">Also listed on:</p>
        <div className="df">
          {cryptoExs.map((ex) => (
            <div className="icon df cover ar-1" key={ex.icon}>
              <img src={ex.icon} alt="" />
            </div>
          ))}
        </div>
      </div>
    </Providers>
  ) : (
    <>
      <div className="buy-fiat df fd-c">
        <div className="row-amount df ai-st">
          <Input
            {...labelPlay}
            value={amount}
            error={amountError}
            handleChange={(e) => setAmount(e.target.value)}
          />
          <SelectCurrency
            currencys={[...currenciesArray].map((c) => c?.code).sort()}
            value={currency}
            onChange={setCurrency}
            {...props}
            upper={true}
          />
        </div>
        {Boolean(amount) && (
          <div className="row-receive df jc-sb">
            <p className="fixel_16_md">...to receive</p>

            <div className="df fd-c gap-6">
              <div className="df ai-c gap-6">
                <div className="tico_icon"></div>
                <p className="fixel_22">
                  {payToLocaleString(exchangeResult?.tico)}
                </p>
              </div>
              <p className="fixel_14 color-neu">
                1 TICO =
                {exchangeResult?.fromCurrencyAmount / exchangeResult?.tico}{" "}
                <span className="uppercase">{currency}</span>
              </p>
            </div>
          </div>
        )}
      </div>

      <Input
        {...labelBonus}
        value={bonusCode}
        handleChange={(e) => setBonusCode(e.target.value)}
      />

      <div className="buttons df jc-sb">
        <CustomButton
          onClick={back}
          classname={`go-back grey`}
          def={40}
          title={"Cancel"}
        />
        <CustomButton
          onClick={goToProviders}
          classname={`go-buy pr`}
          def={40}
          disabled={!amount}
          title={"Buy"}
        />
      </div>
    </>
  );
}
