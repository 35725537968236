import React, { useEffect, useState } from "react";

import CustomButton from "../../../core/ButtonDefault";
import { payToLocaleString } from "../../../../utils/utils";

function shortenAddress(address) {
  if (address.length < 11) return address;
  const start = address.substring(0, 6);
  const end = address.substring(address.length - 4, address.length);
  return `${start}...${end}`;
}

export default function Confirm(props) {
  const { changePart, addressState, amountState, onConfirm } = props;
  const fullAddress = addressState[0];
  const amount = amountState[0];
  const new_balance = "9999";

  return (
    <div className="withdraw-confirm df fd-c" data-hide-top>
      <h2 className="fixel_26 text-center">Withdraw $TICO</h2>
      <div className="tico-information df fd-c gap-16">
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Withdrawing To</p>
          <p className="fixel_16_sbl">{shortenAddress(fullAddress)}</p>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Amount To Withdraw</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">{payToLocaleString(amount)}</p>
          </div>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Service Fee (1%)</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">20</p>
          </div>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">Balance After Withdrawal </p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">{payToLocaleString(new_balance)}</p>
          </div>
        </div>

        <div className="full-line"></div>

        <div className="row df jc-sb ai-c gap-10 fixel_22">
          <p>Available To Withdraw</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p>5,000</p>
          </div>
        </div>
      </div>

      <div className="buttons df jc-sb">
        <CustomButton
          onClick={() => changePart("flow")}
          classname={`grey`}
          def={40}
          title={"Back"}
        />
        <CustomButton
          onClick={onConfirm}
          classname={`pr`}
          def={40}
          title={"Confirm Withdrawal"}
        />
      </div>
    </div>
  );
}
