import React, { useEffect, useRef, useState } from "react";
// import { gsap } from "gsap/all";
// import store from "../../redux/state";

export default function CustomButton({
  classname,
  type = "button",
  def,
  children,
  onClick,
  title,
  ...props
}) {
  // const button = useRef(null);

  // const [anim, setAnim] = useState(null);
  // const mobile = store.getState().isMobile;

  // useEffect(() => {
  //   let gs = gsap.to([button.current], {
  //     backgroundImage:
  //       "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), radial-gradient(285.38% 202.03% at 0% -42.86%, #00FF94 0%, #1DEBA0 11.98%, #2490F0 43.75%, #6A53F3 54.69%, #8749ED 66.67%, #6A53F3 77.6%, #2490F0 89.06%, #1DEBA0 100%)",
  //     duration: 0.3,
  //     ease: "none",
  //     paused: true,
  //   });
  //   setAnim(gs);

  //   return () => {};
  // }, []);

  const classList = () => {
    let defClass = "";
    switch (def) {
      case 40:
        defClass = "type-40 br-16";
        break;
      case 32:
        defClass = "type-32 br-14";
        break;
      case 24:
        defClass = "type-24 br-12";
        break;
      case 14:
        defClass = "type-14 br-8";
        break;
      default:
        break;
    }

    return `def ${classname ?? ""} ${defClass}`;
  };

  // const hover =
  //   classname.includes("pr") && !mobile
  //     ? {
  //         onMouseEnter: () => anim.restart(),
  //         onMouseLeave: () => anim.reverse(),
  //       }
  //     : {};
  return (
    <button
      onClick={onClick}
      className={classList()}
      {...props}
      type={type}
      // ref={button}
    >
      {title && <span className="txt">{title}</span>}
      {children}
    </button>
  );
}
