import { useLocation } from 'react-router-dom';
import useAxiosREST from '../utils/hooks/useAxiosAPI';
import { useContext, useEffect } from 'react';
import AuthContext from './AuthProvider';

export default function AutoLoginLinkProvider({ children }) {
  const { search } = useLocation();
  const { fetchAPI } = useAxiosREST();
  const { setAuth } = useContext(AuthContext);

  const login = async (autoLoginHash) => {
    const res = await fetchAPI.post('auth/login-with-auto-login-link', {
      autoLoginHash,
    });

    if (res?.success) {
      setAuth({ ...res })
      state.setPopUp("");
    }
  }
  
  useEffect(() => {
    const query = new URLSearchParams(search);
    const autoLoginHash = query.get("autoLogin");

    if (autoLoginHash) {
      // Call the login function with the autoLoginHash
      login(autoLoginHash);
    }
  }, [search])

  return children;
}