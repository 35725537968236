import { useEffect } from "react";

const SimplexWidget = () => {
  useEffect(() => {
    const public_key = process.env.REACT_SIMPLEX_PUBLIC_KEY;
    const wallet_id = process.env.REACT_SIMPLEX_WALLET_ID;
    const payment_id = window.uuid;
    const quote_id = window.quote_id;

    // window.simplexAsyncFunction = function () {
    //   Simplex.init({ public_key: public_key });
    // };

    // Динамічно додаємо скрипт Simplex до сторінки
    const script = document.createElement("script");
    script.src = "https://cdn.test-simplexcc.com/sdk/v1/js/sdk.js";
    script.async = true;
    script.onload = () => {
      // window.simplexAsyncFunction();
      Simplex.init({ public_key });
    };
    document.body.appendChild(script);

    // Очищення скрипта після демонтажу компоненту
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div id="simplex-widget-container"></div>
      <form id="simplex-form">
        <div id="checkout-element"></div>
      </form>
    </div>
  );
};

export default SimplexWidget;
