import React from "react";
import EnterViaSocials from "./enter-buttons/EnterViaSocials";
import RegistrationLabels from "./RegistrationLabels";

export default function Registration({ state }) {
  return (
    <>
      <div className="title">
        <p className="t fixel_36">Welcome to Funtico</p>
      </div>

      <div className="content_data df fd-c">
        {/* <EnterViaSocials state={state} />
        <div className="or df ai-c fixel_16_sbl">OR</div> */}
        <RegistrationLabels state={state} />
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">Already Have An Acoount?</p>
        <button className="def wt" onClick={(e) => state.setPopUp("login")}>
          Sign in now
        </button>
      </div>
    </>
  );
}
