import React, { useEffect, Suspense, useContext, useState } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import DataContext from "./context/DataProvider";
import Layout from "./layouts/Layout";
import Preloader from "./components/layout/Preloader";
import getUserData from "./components/core/GetUserData";
import AuthContext from "./context/AuthProvider";
import "./assets/scss/index.scss";
import useAxiosREST from "./utils/hooks/useAxiosAPI";
import { checkSearchParams } from "./utils/utils";

export default function App({ state }) {
  const { data } = useContext(DataContext);
  const { auth } = useContext(AuthContext);

  const [routes, setRoutes] = useState();

  const params = useSearchParams();
  const fetch = useAxiosREST();
  const { getUser } = getUserData();

  const runSearchParamsHooks = async () => {
    await checkSearchParams(params, fetch, state, auth);
  }

  useEffect(() => {
    runSearchParamsHooks()
    return () => {};
  }, []);

  useEffect(() => {
    if (data && data.routes) {
      const createRoute = ({ component: Component, children, ...e }) => {
        return (
          <Route
            key={e.path}
            {...e}
            element={
              <Suspense>
                <Component state={state} />
              </Suspense>
            }
          >
            {children?.map(createRoute)}
          </Route>
        );
      };

      const routes = data.routes.map(createRoute);

      setRoutes(routes);
    }
    return () => {};
  }, [data]);

  useEffect(() => {
    if (auth?.token && !auth?.wallet) getUser();
    return () => {};
  }, [auth.token]);

  return (
    <>
      {<Preloader state={state} />}
      {data && routes ? (
        <>
          <Routes>
            <Route path="/" element={<Layout state={state} auth={auth} />}>
              {routes}
            </Route>
          </Routes>
        </>
      ) : null}
    </>
  );
}
