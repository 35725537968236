const erc20Regex = /^0x[a-fA-F0-9]{40}$/;

function isValidAddress(walletAddress) {
  return erc20Regex.test(walletAddress);
}

async function checkSearchParams(params, fetch, state, auth) {
  const [searchParams, setSearchParams] = params;
  const { fetchAPI } = fetch;
  const hash = searchParams.get("hash");
  const id = searchParams.get("id");
  const nftHash = searchParams.get("nftWithdrawalHash");
  const openRegistationForm = searchParams.get("openRegistationForm");
  const openRegistrationForm2 = searchParams.get("openRegistrationForm");
  const dMissionUserId = searchParams.get("tfid");
  const dMissionTaskId = searchParams.get("tftarget");
  const galxe = searchParams.get("galxe");

  const cleare = () => setSearchParams("");

  const getVerifyStatus = async () => {
    const data = new FormData();

    const info = {
      title: "Something went wrong",
      close: "Okay",
      close_callback: () => {
        setTimeout(() => state.setPopUp("login"), 500);
      },
    };

    data.append("id", id);
    data.append("hash", hash);
    const d = await fetchAPI.post("auth/verify", data);
    const { message, success } = d;

    if (d?.status == "already_registered") {
      info.title = "Thanks for being part of the Funtico family!";
      info.subtitle = "You already have a Funtico account";
      cleare();
    }

    if (d?.data?.allow_email || d?.data?.email) {
      info.title = "Welcome aboard, gamer!";
      info.subtitle = "Now you can log in";
      cleare();
    }

    if (d?.message) {
      info.subtitle = d.message;
    }

    state.setPopUp("confirm", { ...info });
  };

  const getWithdrawal = async () => {
    const data = new FormData();

    const info = {
      title: "Your NFT Withdrawal Request Has Been Confirmed!",
      subtitle: `If any further steps are needed, you will be notified via email within 6 hours. Otherwise, your NFT will be sent to the provided wallet address within 24 hours.`,
      close: "Okay",
    };

    data.append("hash", nftHash);
    const d = await fetchAPI.post("user/backpack/confirm-withdrawal", data);
    const { message, success } = d;

    if (!success) {
      if (!success && message) {
        info.title = message;
        info.subtitle = null;
      }
    }

    cleare();
    state.setPopUp("confirm", { ...info });
  };

  hash && id && getVerifyStatus();
  nftHash && getWithdrawal();
  (openRegistationForm || openRegistrationForm2) && state.setPopUp("reg");

  if (
    dMissionUserId &&
    dMissionTaskId === "98b3eed8-c55e-410e-a3a6-7abfb8054abf"
  ) {
    if (!auth?.token) {
      state.setPopUp("login");
    } else {
      try {
        await fetchAPI.get(
          `user/dmission/track/login/${dMissionUserId}/${dMissionTaskId}`
        );
      } catch (error) {}
    }

    if (galxe) {
      try {
        await fetchAPI.get("user/galxe/track/registration");
      } catch (error) {}
    }
  }
}

function convertDate(d) {
  const date = new Date(d);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${day}.${month}.${year}`;
}

const isExternalLink = (url) => {
  const props = { to: url };

  if (url) {
    const origin = location.origin;
    if (url.startsWith(origin)) {
      props.to = url.split(origin).pop();
    } else {
      props["target"] = "_blank";
    }
  }

  return props;
};

function processMediumObject(item) {
  const { title, link, description, pubDate } = item;

  const imgRegex = /<img.*?src="(.*?)"/;
  const imgMatch = imgRegex.exec(description);
  const thumbnail = imgMatch ? imgMatch[1] : null;
  // const textWithoutHTML = description.replace(/<[^>]*>?/gm, "");
  // const sentences = textWithoutHTML.match(/[^.!?]+[.!?]+/g) || [];
  // const summary = sentences.slice(0, 1).join(" ");

  const date = new Date(pubDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return { title, thumbnail, date, link };
}

const payToLocaleString = (num) => {
  const formatted = Number(num).toLocaleString("en-US");
  return formatted;
};

export {
  isValidAddress,
  checkSearchParams,
  convertDate,
  isExternalLink,
  processMediumObject,
  payToLocaleString,
};
