import React, { useEffect, useState } from "react";
import wallet_qr from "../../../../assets/img/wallet/wallet_qr.jpg";
import Input from "../../../core/labels/Input";

import { CopyToClipboard } from "react-copy-to-clipboard";
import CustomButton from "../../../core/ButtonDefault";

const steps = [
  "Go to one of these exchanges:",
  "Buy $TICO on selected exchange",
  "From selected exchange, go to the crypto withdrawal page",
  "Select $TICO as currency to be withdrawn",
  "Select Avalance for the network",
  "Copy and paste the following wallet address into the designated field:",
  "After checking that everything is in order, proceed with the deposit",
];

const walletAddress = "0x2bE13B09Ed2083B88272346b12Ed5A3c33482c1a";
let sto = null;

const Title = ({ id }) => {
  return (
    <p className="df gap-6">
      <span>0{id}</span> {steps[id - 1]}
    </p>
  );
};

export default function Exchange({ back, cryptoExs }) {
  const [copy, setCopy] = useState(false);

  const onCopy = () => {
    sto && clearTimeout(sto);
    setCopy(true);
    sto = setTimeout(() => setCopy(false), 1500);
  };

  return (
    <>
      <p className="fixel_22 text-center">
        To deposit $TICO via an exchange platform, <br /> follow the
        instructions below:
      </p>
      <div className="list-rows df fd-c fixel_16_sbl">
        <Title id={1} />
        <div className="df icons">
          {cryptoExs.map((ex) => (
            <div className="icon df cover ar-1" key={ex.icon}>
              <img src={ex.icon} alt="" />
            </div>
          ))}
        </div>
        <Title id={2} />
        <Title id={3} />
        <Title id={4} />
        <Title id={5} />
        <Title id={6} />

        <div className="wallet-ddress df aic-">
          <Input readOnly={true} value={walletAddress} name="walletAddress" />
          <CopyToClipboard text={walletAddress} onCopy={onCopy}>
            <div
              className={"icon_copy icon-24 ar-1" + (copy ? " copyed" : "")}
            ></div>
          </CopyToClipboard>
        </div>

        <div className="qr as-c df cover ar-1">
          <img src={wallet_qr} alt="" />
        </div>

        <Title id={7} />

        <CustomButton
          onClick={back}
          classname={`go-back grey as-c`}
          def={40}
          title={"Back"}
        />
      </div>
    </>
  );
}
