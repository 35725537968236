import React, { useEffect } from "react";

import Input from "./Input";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Password from "./Password";
import Textarea from "./Textarea";
import Hidden from './Hidden';
import { useConversionParams } from '../../../context/ConversionParamsProvider';
const FormLabels = ({
  formData,
  handleChange,
  invalidFields,
  selectData,
  isSuccess,
  formResponse,
  def,
  labels,
}) => {
  const {
    tfid: dMissionUserId
  } = useConversionParams()

  useEffect(() => {
    return () => {};
  }, [isSuccess]);

  const initLabels = (label) => {
    const obj = {
      key: label.name,
      error: invalidFields[label.name],
      handleChange: handleChange,
      value: formData[label.name] ?? def?.[label.name] ?? "",
      ...label,
    };

    switch (label.type) {
      case "checkbox":
        return <Checkbox {...obj} />;
      case "password":
        return <Password {...obj} />;
      case "select":
        return selectData ? <Select {...obj} list={selectData} /> : null;
      case "textarea":
        return <Textarea {...obj} max={500} />;
      default:
        return <Input {...obj} />;
    }
  };

  return <>
  {dMissionUserId && <Hidden name="tfid" value={dMissionUserId} />}
  {labels.map((f) => initLabels(f))}
  </>;
};

export default FormLabels;
